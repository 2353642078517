export const API_URL = 'https://order.ellaapp.com/admin/web/v1/';
export const API_URL2 = 'https://order.ellaapp.com/admin/web/';
export const LOCAL_PAYMENT_API_URL = "https://192.168.10.11";
export const LOCAL_PAYMENT_PORT = "82";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "https://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://ciscomobilekiosk.ellaapp.com/kioskautologin/1c74ce0ccdd6f112f7f50cb3456da4ac/65a7467bcf4a8202df2a9e9ea570d7bb";
export const page_redirection_time = 30;
export const auto_logout_timer = 3000;
export const timer_time = 30;
export const payment_failed_modal=30;
export const apitimeout=10;
